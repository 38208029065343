import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './blog-post-header5.css'

const BlogPostHeader5 = (props) => {
  return (
    <div className="blog-post-header5-blog-post-header3 thq-section-padding">
      <div className="blog-post-header5-max-width thq-section-max-width">
        <div className="blog-post-header5-section-title">
          <div className="blog-post-header5-title1">
            <div className="blog-post-header5-breadcrumbs">
              <span className="thq-body-small">Blog</span>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
              </svg>
              <span>
                {props.category ?? (
                  <Fragment>
                    <span className="blog-post-header5-text4 thq-body-small">
                      Fort Building
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <span>
              {props.readTime ?? (
                <Fragment>
                  <span className="blog-post-header5-text7 thq-body-small">
                    5 minutes
                  </span>
                </Fragment>
              )}
            </span>
            <div className="blog-post-header5-content1">
              <h1>
                {props.blogPostTitle ?? (
                  <Fragment>
                    <h1 className="blog-post-header5-text5 thq-heading-1">
                      Join the Forting Society: Celebrating the Art of Fort
                      Building
                    </h1>
                  </Fragment>
                )}
              </h1>
              <div className="blog-post-header5-content2">
                <div className="blog-post-header5-author">
                  <span className="thq-body-small">Written by</span>
                  <span>
                    {props.avatarName ?? (
                      <Fragment>
                        <span className="blog-post-header5-text6 thq-body-small">
                          The Forting Society
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="blog-post-header5-time">
                  <span>
                    {props.date ?? (
                      <Fragment>
                        <span className="blog-post-header5-text8 thq-body-small">
                          September 15, 2022
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-post-header5-container">
          <img
            alt={props.blogPostImageAlt}
            src={props.blogPostImageSrc}
            className="blog-post-header5-image1 thq-img-ratio-4-3"
          />
        </div>
      </div>
    </div>
  )
}

BlogPostHeader5.defaultProps = {
  category: undefined,
  blogPostImageAlt: 'Forting Society Blog Post Image',
  blogPostTitle: undefined,
  blogPostImageSrc:
    'https://images.unsplash.com/photo-1442504028989-ab58b5f29a4a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNTY1MTY5Mnw&ixlib=rb-4.0.3&q=80&w=1080',
  avatarName: undefined,
  readTime: undefined,
  date: undefined,
}

BlogPostHeader5.propTypes = {
  category: PropTypes.element,
  blogPostImageAlt: PropTypes.string,
  blogPostTitle: PropTypes.element,
  blogPostImageSrc: PropTypes.string,
  avatarName: PropTypes.element,
  readTime: PropTypes.element,
  date: PropTypes.element,
}

export default BlogPostHeader5
