import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import ContentList1 from '../components/content-list1'
import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Contact10 from '../components/contact10'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Forting Society ⛺️</title>
        <meta
          name="description"
          content="The official website for The Forting Society of America (satirical, not a registered entity). This describes our mission, news, testimonials, and resources"
        />
        <meta property="og:title" content="Forting Society ⛺️" />
        <meta
          property="og:description"
          content="The official website for The Forting Society of America (satirical, not a registered entity). This describes our mission, news, testimonials, and resources"
        />
      </Helmet>
      <Navbar8
        text={
          <Fragment>
            <span className="home-text10">Testimonials</span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="home-text11 thq-link thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text12 thq-link thq-body-small">About</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text13 thq-link thq-body-small">News</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text14 thq-link thq-body-small">
              Testimonials
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text15 thq-body-large">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text16 thq-body-large">About</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text17 thq-body-large">News</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text18 thq-body-large">Testimonials</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text19">Join the Forting Society!</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text20">Share Your Testimonial</span>
          </Fragment>
        }
        textUrl="#Testimonial17"
        link1Url="#Features24"
        link2Url="#Hero17"
        link3Url="#ContentList1"
        page1Description={
          <Fragment>
            <span className="home-text21 thq-body-small">
              Welcome to The Forting Society of America!
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text22 thq-body-small">
              Learn about our mission to provide resources for fort builders of
              all ages.
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text23 thq-body-small">
              Stay updated with the latest news and events related to fort
              building.
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text24 thq-body-small">
              Discover what others have to say about their experiences with our
              organization.
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text25 thq-body-small">Learn More</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text26 thq-body-small">Join Us!</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text27 thq-body-large">
              Join us in our mission to celebrate the art of fort building and
              provide resources for fort builders of all ages. Let&apos;s spread
              awareness and appreciation for the joy of fort construction!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text28 thq-heading-1">
              Welcome to The Forting Society of America
            </span>
          </Fragment>
        }
        hero17Id="Hero17"
      ></Hero17>
      <ContentList1
        heading={
          <Fragment>
            <span className="home-text29">News</span>
          </Fragment>
        }
        contentList1Id="ContentList1"
      ></ContentList1>
      <Features24
        features24Id="Features24"
        feature1Title={
          <Fragment>
            <span className="home-text30 thq-heading-2">Fun for All Ages</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text31 thq-heading-2">
              Interactive Workshops
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text32 thq-heading-2">
              Fort Building Resources
            </span>
          </Fragment>
        }
        feature1ImgSrc="/designer%20(8)-1400w.jpeg"
        feature1Description={
          <Fragment>
            <span className="home-text33 thq-body-small">
              Join us in the joy of fort building, no matter your age
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text34 thq-body-small">
              Engaging fort building workshops and events
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text35 thq-body-small">
              Access to a variety of fort building materials and resources
            </span>
          </Fragment>
        }
      ></Features24>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text36">Sign Up Now</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text37 thq-body-large">
              Become a member today and gain access to exclusive fort building
              resources and events.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text38 thq-heading-2">
              Join the Forting Society of America!
            </span>
          </Fragment>
        }
      ></CTA26>
      <Features25
        feature1Title={
          <Fragment>
            <span className="home-text39 thq-heading-2">
              Fort Building Resources
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text40 thq-heading-2">Community Events</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text41 thq-heading-2">
              Fort Building Workshops
            </span>
          </Fragment>
        }
        feature1ImgSrc="/designer%20(4)-1400w.jpeg"
        feature1Description={
          <Fragment>
            <span className="home-text42 thq-body-small">
              Access a wide range of resources, from fort building tips and
              tricks to creative design ideas.
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text43 thq-body-small">
              Join us for fun community events where fort builders of all ages
              can come together to share their passion.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text44 thq-body-small">
              Participate in our hands-on fort building workshops led by
              experienced builders to enhance your skills.
            </span>
          </Fragment>
        }
      ></Features25>
      <Steps2
        step1Title={
          <Fragment>
            <span className="home-text45 thq-heading-2">Gather Materials</span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text46 thq-heading-2">Choose a Location</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text47 thq-heading-2">Build Your Fort</span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text48 thq-heading-2">
              Personalize and Enjoy
            </span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text49 thq-body-small">
              Collect blankets, pillows, chairs, and any other materials you
              want to use for building your fort.
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text50 thq-body-small">
              Find a suitable spot in your home or backyard where you can build
              your fort without any interruptions.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text51 thq-body-small">
              Use your gathered materials to construct a cozy and sturdy fort
              that fits your vision.
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text52 thq-body-small">
              Add personal touches like fairy lights, snacks, and comfy seating
              to make your fort a perfect hideaway for relaxation and fun.
            </span>
          </Fragment>
        }
      ></Steps2>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text53 thq-body-small">
              Building pillow forts is pure joy! Transforming a living room into
              a cozy fortress with cushions, blankets, and chairs is a blast.
              The fun lies in the creativity of constructing the fort and the
              delight of crawling inside, wrapped in your own cozy world. It’s a
              simple pleasure that sparks imagination and offers a comforting
              escape from daily life.
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text54 thq-body-small">
              The resources provided by The Forting Society of America are
              invaluable. They truly understand the joy and creativity that fort
              building brings to people of all ages.
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text55 thq-body-small">
              As a young fort builder, I feel inspired by the community fostered
              by The Forting Society of America. It&apos;s a place where my
              imagination can run wild!
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text56 thq-body-small">
              The dedication of The Forting Society of America to preserving the
              art of fort building is commendable. I&apos;m proud to be part of
              such a passionate group.
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text57 thq-body-small">
              Joining The Forting Society of America has been a game-changer for
              me. I&apos;ve learned new fort-building techniques and connected
              with fellow fort enthusiasts. Highly recommend!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text58 thq-heading-2">Testimonials</span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <span className="home-text59 thq-body-large">Samantha W.</span>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <span className="home-text60 thq-body-large">Michael R.</span>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <span className="home-text61 thq-body-large">Emily S.</span>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <span className="home-text62 thq-body-large">David L.</span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text63 thq-body-small">Fort Enthusiast</span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text64 thq-body-small">
              Chief Fort Architect
            </span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text65 thq-body-small">
              Junior Fort Builder
            </span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text66 thq-body-small">Fort Historian</span>
          </Fragment>
        }
        testimonial17Id="Testimonial17"
      ></Testimonial17>
      <Contact10
        content1={
          <Fragment>
            <span className="home-text67 thq-body-large">
              Have a question or want to get in touch with us? Feel free to
              reach out using the contact information below.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text68 thq-heading-2">Contact Us</span>
          </Fragment>
        }
        location1={
          <Fragment>
            <span className="home-text69 thq-heading-3">
              Forting Society of America Headquarters
            </span>
          </Fragment>
        }
        location2={
          <Fragment>
            <span className="home-text70 thq-heading-3">General Inquiries</span>
          </Fragment>
        }
        location1ImageSrc="/designer%20(3)-1400w.jpeg"
        location2ImageSrc="/designer%20(6)-1400w.jpeg"
        location1Description={
          <Fragment>
            <span className="home-text71 thq-body-large">
              123 Fort Builders Lane, Fort City, USA
            </span>
          </Fragment>
        }
        location2Description={
          <Fragment>
            <span className="home-text72 thq-body-large">
              Email: info@fortingsocietyofamerica.org
            </span>
          </Fragment>
        }
      ></Contact10>
      <Footer4
        link1={
          <Fragment>
            <span className="home-text73 thq-body-small">About Us</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text74 thq-body-small">News</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text75 thq-body-small">Testimonials</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text76 thq-body-small">Contact Us</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text77 thq-body-small">
              Join The Forting Society
            </span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="home-text78 thq-body-small">
              Terms and Conditions
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text79 thq-body-small">Cookies Policy</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text80 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home
