import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './blog-post-header55.css'

const BlogPostHeader55 = (props) => {
  return (
    <div className="blog-post-header55-blog-post-header3 thq-section-padding">
      <div className="blog-post-header55-max-width thq-section-max-width">
        <div className="blog-post-header55-section-title">
          <div className="blog-post-header55-title1">
            <div className="blog-post-header55-breadcrumbs">
              <span className="thq-body-small">Blog</span>
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
              </svg>
              <span>
                {props.category ?? (
                  <Fragment>
                    <span className="blog-post-header55-text18 thq-body-small">
                      Fort Building
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <span>
              {props.readTime ?? (
                <Fragment>
                  <span className="blog-post-header55-text19 thq-body-small">
                    5 minutes
                  </span>
                </Fragment>
              )}
            </span>
            <div className="blog-post-header55-content1">
              <h1>
                {props.blogPostTitle ?? (
                  <Fragment>
                    <h1 className="blog-post-header55-text15 thq-heading-1">
                      Join the Forting Society and Unleash Your Creativity
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span>
                {props.text ?? (
                  <Fragment>
                    <span className="blog-post-header55-text17">Text</span>
                  </Fragment>
                )}
              </span>
              <div className="blog-post-header55-content2">
                <div className="blog-post-header55-author">
                  <span className="thq-body-small">Written by</span>
                  <span>
                    {props.avatarName ?? (
                      <Fragment>
                        <span className="blog-post-header55-text14 thq-body-small">
                          The Forting Society
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <div className="blog-post-header55-time">
                  <span>
                    {props.date ?? (
                      <Fragment>
                        <span className="blog-post-header55-text16 thq-body-small">
                          September 15, 2022
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-post-header55-container">
          <img
            alt={props.blogPostImageAlt}
            src={props.blogPostImageSrc}
            className="blog-post-header55-image1 thq-img-ratio-4-3"
          />
        </div>
      </div>
    </div>
  )
}

BlogPostHeader55.defaultProps = {
  avatarName: undefined,
  blogPostTitle: undefined,
  blogPostImageSrc:
    'https://images.unsplash.com/photo-1712903277162-a151fbb661cd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNTY1NTA1MXw&ixlib=rb-4.0.3&q=80&w=1080',
  date: undefined,
  text: undefined,
  blogPostImageAlt: 'Fort Building Workshop',
  category: undefined,
  readTime: undefined,
}

BlogPostHeader55.propTypes = {
  avatarName: PropTypes.element,
  blogPostTitle: PropTypes.element,
  blogPostImageSrc: PropTypes.string,
  date: PropTypes.element,
  text: PropTypes.element,
  blogPostImageAlt: PropTypes.string,
  category: PropTypes.element,
  readTime: PropTypes.element,
}

export default BlogPostHeader55
