import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="thq-heading-2">Discover the Power of Forting</h2>
            <p className="thq-body-large">
              Discovering the art of building pillow forts is like unlocking a
              treasure chest of childhood magic. It begins with the thrill of
              seeing everyday items—cushions, blankets, and chairs—in a new
              light, each piece a potential building block for your dream
              fortress. As you experiment with different arrangements and
              techniques, you learn to master the delicate balance of stability
              and creativity. The process becomes a delightful puzzle, with each
              fort a unique masterpiece reflecting your imagination. This simple
              yet profound skill not only reignites a sense of wonder but also
              reminds you of the joy found in transforming the ordinary into
              something extraordinary.
            </p>
            <div className="steps2-actions">
              <button className="thq-button-animated thq-button-filled steps2-button">
                <span className="thq-body-small">
                  <span>Learn More</span>
                  <br></br>
                </span>
              </button>
            </div>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              <h2>
                {props.step1Title ?? (
                  <Fragment>
                    <h2 className="steps2-text31 thq-heading-2">
                      Gather Materials
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text28 thq-body-small">
                      Collect blankets, pillows, chairs, and any other materials
                      you want to use for building your fort.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text17 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2>
                {props.step2Title ?? (
                  <Fragment>
                    <h2 className="steps2-text34 thq-heading-2">
                      Choose a Location
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text29 thq-body-small">
                      Find a suitable spot in your home or backyard where you
                      can build your fort without any interruptions.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text20 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2>
                {props.step3Title ?? (
                  <Fragment>
                    <h2 className="steps2-text30 thq-heading-2">
                      Build Your Fort
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text27 thq-body-small">
                      Use your gathered materials to construct a cozy and sturdy
                      fort that fits your vision.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text23 thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              <h2>
                {props.step4Title ?? (
                  <Fragment>
                    <h2 className="steps2-text33 thq-heading-2">
                      Personalize and Enjoy
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step4Description ?? (
                  <Fragment>
                    <span className="steps2-text32 thq-body-small">
                      Add personal touches like fairy lights, snacks, and comfy
                      seating to make your fort a perfect hideaway for
                      relaxation and fun.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text26 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step3Description: undefined,
  step1Description: undefined,
  step2Description: undefined,
  step3Title: undefined,
  step1Title: undefined,
  step4Description: undefined,
  step4Title: undefined,
  step2Title: undefined,
}

Steps2.propTypes = {
  step3Description: PropTypes.element,
  step1Description: PropTypes.element,
  step2Description: PropTypes.element,
  step3Title: PropTypes.element,
  step1Title: PropTypes.element,
  step4Description: PropTypes.element,
  step4Title: PropTypes.element,
  step2Title: PropTypes.element,
}

export default Steps2
