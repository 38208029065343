import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import BlogPostHeader55 from './blog-post-header55'
import BlogPostHeader52 from './blog-post-header52'
import BlogPostHeader5 from './blog-post-header5'
import './content-list1.css'

const ContentList1 = (props) => {
  return (
    <div
      id={props.contentList1Id}
      className="content-list1-container thq-section-padding"
    >
      <div className="content-list1-max-width thq-section-max-width">
        <h1>
          {props.heading ?? (
            <Fragment>
              <h1 className="content-list1-text28">News</h1>
            </Fragment>
          )}
        </h1>
      </div>
      <BlogPostHeader55
        date={
          <Fragment>
            <span className="content-list1-text11 thq-body-small">
              September 6, 2024
            </span>
          </Fragment>
        }
        text={
          <Fragment>
            <span className="content-list1-text12">
              The board of directors has consulted with the Forting Union of
              America, and voted on an official presidential endorsment. We
              believe that Harris is the clear pick, due to her pro-forting
              policies, that will help provide funding to forting resource
              programs, whereas her opponent is pushing anti-forting propeganda,
              that is not based an any evidence.
            </span>
          </Fragment>
        }
        category={
          <Fragment>
            <span className="content-list1-text13 thq-body-small">
              Fort Building
            </span>
          </Fragment>
        }
        readTime={
          <Fragment>
            <span className="content-list1-text14 thq-body-small">
              5 minutes
            </span>
          </Fragment>
        }
        avatarName={
          <Fragment>
            <span className="content-list1-text15 thq-body-small">
              The Forting Society
            </span>
          </Fragment>
        }
        blogPostTitle={
          <Fragment>
            <span className="content-list1-text16 thq-heading-1">
              The Forting Society Endorses Kamala Harris for President
            </span>
          </Fragment>
        }
        blogPostImageSrc="/37164349802_bff954eeec_b-600h.jpg"
      ></BlogPostHeader55>
      <BlogPostHeader52
        date={
          <Fragment>
            <span className="content-list1-text17 thq-body-small">
              August 30, 2024
            </span>
          </Fragment>
        }
        text={
          <Fragment>
            <span className="content-list1-text18">
              National Forting Day is coming up. Get the latest on the upcoming
              celebrations.
            </span>
          </Fragment>
        }
        category={
          <Fragment>
            <span className="content-list1-text19 thq-body-small">
              Fort Building
            </span>
          </Fragment>
        }
        readTime={
          <Fragment>
            <span className="content-list1-text20 thq-body-small">
              5 minutes
            </span>
          </Fragment>
        }
        avatarName={
          <Fragment>
            <span className="content-list1-text21 thq-body-small">
              The Forting Society
            </span>
          </Fragment>
        }
        blogPostTitle={
          <Fragment>
            <span className="content-list1-text22 thq-heading-1">
              Celebrate the Art of Fort Building
            </span>
          </Fragment>
        }
        blogPostImageSrc="/designer%20(9)-600w.jpeg"
      ></BlogPostHeader52>
      <BlogPostHeader5
        date={
          <Fragment>
            <span className="content-list1-text23 thq-body-small">
              Augist 15, 2024
            </span>
          </Fragment>
        }
        category={
          <Fragment>
            <span className="content-list1-text24 thq-body-small">
              Fort Building
            </span>
          </Fragment>
        }
        readTime={
          <Fragment>
            <span className="content-list1-text25 thq-body-small">
              5 minutes
            </span>
          </Fragment>
        }
        avatarName={
          <Fragment>
            <span className="content-list1-text26 thq-body-small">
              The Forting Society
            </span>
          </Fragment>
        }
        blogPostTitle={
          <Fragment>
            <span className="content-list1-text27 thq-heading-1">
              Join the Forting Society: Celebrating the Art of Fort Building
            </span>
          </Fragment>
        }
        blogPostImageSrc="/designer%20(5)-600w.jpeg"
      ></BlogPostHeader5>
    </div>
  )
}

ContentList1.defaultProps = {
  contentList1Id: '',
  heading: undefined,
}

ContentList1.propTypes = {
  contentList1Id: PropTypes.string,
  heading: PropTypes.element,
}

export default ContentList1
